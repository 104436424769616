<template>
  <div>
    <portal to="title-page">
      <h1>Crear tour</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPlus}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                label="Idioma"
                :prepend-icon="mdiFlag"
                :value="language ? language.name : ''"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="py-0">
              <v-text-field
                color="#f88511"
                v-model="tourUrl"
                required
                :rules="[rules.required]"
                :label="`Url en ` +(language ? language.name : '') "
                :prepend-icon="mdiWeb"
                
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" class="py-0">
              <v-text-field
                color="#f88511"
                v-model="tour.name"
                required
                :rules="[rules.required]"
                label="Tour"
                :prepend-icon="mdiText"
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" class="py-0">
              <v-text-field
                color="#f88511"
                v-model="tour.title"
                required
                :rules="[rules.required]"
                label="Titulo"
                :prepend-icon="mdiFormatTitle"
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" class="py-0">
              <v-select
                v-model="tour.includeATVs"
                :items="[{text:'No', value: 'no'},{text:'Si', value: 'yes'}]"
                item-text="text"
                item-value="value"
                label="Incluye ATVs"
                dense
              ></v-select>
            </v-col>
            <v-col md="12" class="py-0">
              <v-textarea
                class=""
                v-model="tour.metadescription"
                label="Metadescripcion"
                rows="2"
                counter
                hint="Se recomienda maximo 160 caracteres"
                prepend-icon="mdi-comment"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="py-0">
              <p>Descripcion</p>
              <vue-editor
                :class="tour.description != null && tour.description == '' ? 'error' : ''"
                color="#f88511"
                v-model="tour.description"
                :editor-toolbar="customToolbar"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                dense
              ></vue-editor>
            </v-col>
            <v-col md="6" class="py-0">
              <p>Incluye</p>
              <vue-editor
                :class="tour.include != null && tour.include == '' ? 'error' : ''"
                color="#f88511"
                v-model="tour.include"
                :editor-toolbar="customToolbar"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                dense
              ></vue-editor>
            </v-col>
            <v-col md="6" class="">
              <p>Importante</p>
              <vue-editor
                :class="tour.important != null && tour.important == '' ? 'error' : ''"
                color="#f88511"
                v-model="tour.important"
                :editor-toolbar="customToolbar"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                dense
              ></vue-editor>
            </v-col>
            <v-col md="6" class="">
              <p>Recomendaciones</p>
              <vue-editor
                :class="tour.recommendation != null && tour.recommendation == '' ? 'error' : ''"
                color="#f88511"
                v-model="tour.recommendation"
                :editor-toolbar="customToolbar"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                dense
              ></vue-editor>
            </v-col>
            <v-col md="12" class="my-4">
              <v-btn
                :disabled="disableBtnAdd"
                color="primary"
                class="mr-4"
                @click="validateForm('add')"
              >
                <v-icon>{{mdiContentSave}}</v-icon> Agregar
              </v-btn>
              <v-btn
                :disabled="disableBtnAdd"
                color="success"
                class="mr-4"
                @click="validateForm('images')"
              >
                <v-icon>{{mdiImageMultiple}}</v-icon> Continuar con imagenes
              </v-btn>
              <v-btn
                :disabled="disableBtnAdd"
                class="mr-4"
                @click="validateForm('rates')"
              >
                <v-icon>{{mdiCurrencyUsd}}</v-icon> Continuar con tarifas
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Tour from "@/models/Tour.js";
  //import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
  import { mdiArrowLeft, mdiText, mdiFlag, mdiPlus, mdiFormatTitle, mdiImageMultiple, mdiCurrencyUsd, mdiContentSave, mdiClose, mdiWeb } from '@mdi/js';
  import Language from "@/models/Language.js";
  export default {
    name: "TourCreate",
    //components: { VueEditor },
    data() {
      return {
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiText:mdiText,
        mdiFlag:mdiFlag,
        mdiWeb:mdiWeb,
        mdiFormatTitle:mdiFormatTitle,
        mdiImageMultiple:mdiImageMultiple,
        mdiCurrencyUsd:mdiCurrencyUsd,
        mdiContentSave:mdiContentSave,
        mdiClose:mdiClose,
        ///////////////////////////////////////////////////////////////////////
        isLoading:true,
        validForm:true,
        ///////////////////////////////////////////////////////////////////////
        languageModel:new Language,
        tourModel:new Tour,
        ///////////////////////////////////////////////////////////////////////
        tourUrl:'',
        language:null,
        validFormInit:true,
        tour:{
          description:null,
          important:null,
          recommendation:null,
          include:null,
        },
        rules: {
          required: (v) => !!v || "El campo es obligatorio"
        },
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["code-block"],
        ],
        snackbar: {
          show: false,
          message: "",
          color: "",
        },
        // valores imagen
      };
    },
    methods: {
      add() {
      },
      addImg() {
      },
      validateForm(action) {
        this.validFormInit = false;
        this.tour.description = this.tour.description == null ? '' : this.tour.description;
        this.tour.important = this.tour.important == null ? '' : this.tour.important;
        this.tour.recommendation = this.tour.recommendation == null ? '' : this.tour.recommendation;
        this.tour.include = this.tour.include == null ? '' : this.tour.include;
        if (this.$refs.form.validate()) {
          //let loading = this.$loading.show();
          this.isLoading = true;
          //console.log('this.tourUrl, this.language.id', this.tourUrl, this.language.id);
          let prefix = this.language.id == 'es' ? '' : `/${this.language.id}`;
          let fullUrl = `${prefix}/tours/${this.tourUrl}`;
          this.tourModel.findUrl(fullUrl, this.language.id).get().then(snap => {
            if (snap.empty) {
              this.tourModel.add(this.tour, this.language).then((doc) => {
                doc.collection('tourUrls')
                .doc(doc.id+this.language.id)
                .set({
                  tourId:doc.id,
                  deleted:0,
                  fullUrl:fullUrl,
                  url:this.tourUrl,
                  language:this.language.id
                }).then(() => {
                  //loading.hide();
                  this.showSnackbarSuccess('El tour se ha agregado correctamente')
                  if (action == 'images') {
                    this.$router.push({path:`/backend/admin/tours/${doc.id}/images`})
                  } else if(action == 'rates') {
                    this.$router.push({path:`/backend/admin/tours/${doc.id}/rates`})
                  } else {
                    this.$router.push({path:`/backend/admin/tours`})
                  }
                  
                })
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
                this.showSnackbarError('Error al crear el tour');
              })
              this.isLoading = false;
            } else {
              this.showSnackbarError('La url "'+this.tourUrl+ '" ya esta registrada, ingrese otra');
              this.isLoading = false;
            }
          });
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.languages = {};
        snap.forEach( doc => {
          this.languages[doc.id] = doc.data();
        })
        this.language = this.languages.es;
        this.tour.language = 'es';
        this.isLoading = false;
      })
    },
    computed:{
      disableBtnAdd : function() {
        if (this.validFormInit) {
          return false;
        }
        if (!this.tour.description || !this.tour.important || !this.tour.recommendation || !this.tour.include) {
          return true;
        }
        return false;
      }
    },
    created() {
      this.$store.commit("SET_LAYOUT", "admin");
    },
  };
</script>
<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>




